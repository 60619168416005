import React from 'react'

function SavedAppliances() {
  return (
    <div>
      This is for Saved Appliances
    </div>
  )
}

export default SavedAppliances
