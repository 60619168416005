

function App() {
  return (
    <div className="App">
      
      <header className="App-header">
        <img src="https://cdn.dribbble.com/users/958543/screenshots/2868176/media/94b90e57dd3c9a3d3d346d350e3288f6.gif" className="App-logo" alt="logo" />
        <p>
            AppliCare -- Coming Soon
        </p>
        <a className="App-Link" href="https://www.figma.com/proto/BtVlwtFLWtipw3KQ2gqCy8/AppliCare-layout?node-id=1-2&node-type=canvas&t=Y1ELpDDGVvngeczp-0&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=1%3A2" target="_blank">
            Click here to view the layout of the webpage
        </a>
      
      </header>
    </div>
  );
}

export default App;
