import React from 'react'

function ServiceMap() {
  return (
    <div>
      This is for our Service Map
    </div>
  )
}

export default ServiceMap
