import React from 'react'

function ApplianceParts() {
  return (
    <div>
      This is the page for Appliance Parts
    </div>
  )
}

export default ApplianceParts
