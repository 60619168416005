import React, { useState, useContext } from 'react';
import { Container, Row, Col, Card, Form, Button, Accordion, Modal, ListGroup } from 'react-bootstrap';
import { CartContext } from '../contexts/CartContext';

function CommonIssues() {
    
    const { cart, addToCart, removeFromCart } = useContext(CartContext)
    const [showCart, setShowCart] = useState(false)

    const parts = [
        { id: 1, name: 'Appliance Part 1', price: 15 },
        { id: 2, name: 'Appliance Part 2', price: 25 },
        { id: 3, name: 'Appliance Part 3', price: 5 },
        { id: 4, name: 'Appliance Part 3', price: 10 },
        { id: 5, name: 'Appliance Part 3', price: 20 },
    ]

    const handleShowCart = () => setShowCart(true)
    const handleCloseCart = () => setShowCart(false)

    return (
      <div style={{ backgroundColor: "var(--gray_50)" }}>
        <Container className="my-5">
  
          <div className="bgColor" style={{ backgroundColor: "var(--blue_a200_7f)", borderRadius: "16px", padding: "20px" }}>
            <h2 className="text-center mb-4" style={{ fontWeight: "bold" }}>Common Issues</h2>
            <p className="text-center mb-5">
              This section lists all the common problems you appliance might have.
            </p>
          </div>

            {/* Washers Section */}
            <h1 id='washer' style={{marginTop: "50px"}}>Washers</h1>

                <Accordion defaultActiveKey="0">
                    {/* First Accordion Item */}
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><strong>Door Won't Open</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href="https://www.youtube.com/watch?v=bQdniahf0bU" target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="https://www.google.com/maps/search/washer+fix+services/@40.6322614,-74.321101,12z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                            
                            <div className="parts-container">
                                <div className="row">
                                    {parts.map((part) => (
                                        <div key={part.id} className="col-md-4 mb-3">
                                            <div className="card text-center h-100">
                                                <div className="card-body">
                                                    <h5 className="card-title">{part.name}</h5>
                                                    <p className="card-text">${part.price}</p>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        onClick={() => addToCart(part)}
                                                    >
                                                        Save Part
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            
                            {/* Button to Open Cart  */}

                            <Button variant="success" onClick={handleShowCart}>
                                View Saved Parts ({cart.length})
                            </Button>
                        
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Second Accordion Item */}
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><strong>Not Washing Properly</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href="https://www.youtube.com/watch?v=KmPlBZ5nT1Q" target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="https://www.google.com/maps/search/washer+fix+services/@40.6322614,-74.321101,12z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:

                            <div className="parts-container">
                                <div className="row">
                                    {parts.map((part) => (
                                        <div key={part.id} className="col-md-4 mb-3">
                                            <div className="card text-center h-100">
                                                <div className="card-body">
                                                    <h5 className="card-title">{part.name}</h5>
                                                    <p className="card-text">${part.price}</p>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        onClick={() => addToCart(part)}
                                                    >
                                                        Save Part
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            
                            {/* Button to Open Cart  */}

                            <Button variant="success" onClick={handleShowCart}>
                                View Saved Parts ({cart.length})
                            </Button>

                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Third Accordion Item */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><strong>Won't Turn On</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href="https://www.youtube.com/watch?v=KmPlBZ5nT1Q" target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="https://www.google.com/maps/search/washer+fix+services/@40.6322614,-74.321101,12z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:

                            <div className="parts-container">
                                <div className="row">
                                    {parts.map((part) => (
                                        <div key={part.id} className="col-md-4 mb-3">
                                            <div className="card text-center h-100">
                                                <div className="card-body">
                                                    <h5 className="card-title">{part.name}</h5>
                                                    <p className="card-text">${part.price}</p>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        onClick={() => addToCart(part)}
                                                    >
                                                        Save Part
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            
                            {/* Button to Open Cart  */}

                            <Button variant="success" onClick={handleShowCart}>
                                View Saved Parts ({cart.length})
                            </Button>

                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>

                {/* Dryers Section */}
                <h1 id='dryer' style={{marginTop: "50px"}}>Dryers</h1>

                <Accordion defaultActiveKey="0">
                    {/* First Accordion Item */}
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><strong>Door Won't Open</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href="https://www.youtube.com/watch?v=sjS_P4awlp4" target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="https://www.google.com/maps/search/dryer+fix+near+me/@40.6486948,-74.256504,13z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MTIwMy4wIKXMDSoASAFQAw%3D%3D" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Second Accordion Item */}
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><strong>Not Drying Properly</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href=" " target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="https://www.google.com/maps/search/dryer+fix+near+me/@40.6486948,-74.256504,13z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MTIwMy4wIKXMDSoASAFQAw%3D%3D" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Third Accordion Item */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><strong>Won't Turn On</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href=" " target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="https://www.google.com/maps/search/dryer+fix+near+me/@40.6486948,-74.256504,13z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MTIwMy4wIKXMDSoASAFQAw%3D%3D" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>

                {/* Fridge Section */}
                <h1 id='dryer' style={{marginTop: "50px"}}>Refridgerators</h1>

                <Accordion defaultActiveKey="0">
                    {/* First Accordion Item */}
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><strong>Door Is Not Fully Closing</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href=" " target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="https://www.google.com/maps/search/refrigerator+repair+near+me/@40.6486729,-74.2977053,12z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MTIwMy4wIKXMDSoASAFQAw%3D%3D" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Second Accordion Item */}
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><strong>Not Cooling Properly / Temperature Inconsistencies</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href=" " target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="https://www.google.com/maps/search/refrigerator+repair+near+me/@40.6486729,-74.2977053,12z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MTIwMy4wIKXMDSoASAFQAw%3D%3D" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Third Accordion Item */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><strong>Light Not Turning On</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href=" " target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="https://www.google.com/maps/search/refrigerator+repair+near+me/@40.6486729,-74.2977053,12z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MTIwMy4wIKXMDSoASAFQAw%3D%3D" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>

                {/* Dishwasher Section */}
                <h1 id='dryer' style={{marginTop: "50px"}}>Dishwashers</h1>

                <Accordion defaultActiveKey="0">
                    {/* First Accordion Item */}
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><strong>Water Leakage</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href=" " target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Second Accordion Item */}
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><strong>Not Cleaning Dishes Properly</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href=" " target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Third Accordion Item */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><strong>Won't Turn On</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href=" " target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href=" " target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>

                {/* Microwave Section */}
                <h1 id='dryer' style={{marginTop: "50px"}}>Microwave</h1>

                <Accordion defaultActiveKey="0">
                    {/* First Accordion Item */}
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><strong>Door is Jammed / Stuck</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href=" " target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Second Accordion Item */}
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><strong>Not Heating Food Properly / Heat Inconsistencies</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href=" " target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Third Accordion Item */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><strong>Won't Turn On</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href=" " target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href=" " target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>

                {/* Oven Section */}
                <h1 id='dryer' style={{marginTop: "50px"}}>Ovens</h1>

                <Accordion defaultActiveKey="0">
                    {/* First Accordion Item */}
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><strong>Door Won't Close / Loose</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href=" " target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Second Accordion Item */}
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><strong>Not Heating Up Properly</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href=" " target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href="" target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Third Accordion Item */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><strong>Won't Turn On</strong></Accordion.Header>
                        <Accordion.Body>
                            Youtube Tutorial: <a href=" " target='_blank'>Click Here</a><br /> 
                            Service Wokers Near Me: <a href=" " target='_blank'>Click Here</a><br />
                            Parts Used / Needed:
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>

                {/* Cart Modal */}
                <Modal show={showCart} onHide={handleCloseCart} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Shopping Cart</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {cart.length === 0 ? (
                            <p>Your cart is empty.</p>
                        ) : (
                            <ListGroup>
                                {cart.map((item) => (
                                    <ListGroup.Item key={item.id} className="d-flex justify-content-between align-items-center">
                                        {item.name} - ${item.price}
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => removeFromCart(item.id)}
                                        >
                                            Remove
                                        </Button>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
            
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseCart}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
  
        </Container>
      </div>
    )
  }
  
  export default CommonIssues