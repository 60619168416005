import React from 'react'

function ShoppingCart() {
  return (
    <div>
      This is the Shopping Cart Page
    </div>
  )
}

export default ShoppingCart
