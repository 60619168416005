import React, { useState, createContext, useContext } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { CartContext } from '../contexts/CartContext';

function ShoppingCart() {
  
  const { cart, removeFromCart } = useContext(CartContext)

  const total = cart.reduce((acc, item) => acc + item.price, 0)

  console.log('Current cart contents:', cart)

  return (
    <div>
      <Container className="my-5">

        <div className="bgColor" style={{ backgroundColor: "var(--blue_a200_7f)", borderRadius: "16px", padding: "20px" }}>
          <h2 className="text-center mb-4" style={{ fontWeight: "bold" }}>Shopping Cart</h2>
          <p className="text-center mb-5">
            This section habits all of your items you with to purchase.
          </p>
        </div>

        <div>
          {cart.length === 0 ? (
            <p>Your cart is Empty</p>
          ) : (
            <div>
              <ul>
                {cart.map((item) => (
                  <li key={item.id}>
                    {item.name} - ${item.price}{' '}
                    <button onClick={() => removeFromCart(item.id)}>Remove</button>
                  </li>
                ))}
              </ul>
              <h3>Total: ${total}</h3>
            </div>
          )}
        </div>

      </Container>
    </div>
  )
}

export default ShoppingCart
